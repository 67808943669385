@import "antd/dist/reset.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-ja leading-relaxed tracking-wider;
  }
  input:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
    border-color: #60a5fa !important;
    --tw-ring-color: transparent !important;
  }
  input[type="text"] {
    @apply border border-gray-300 rounded-md;
  }
  input[type="email"] {
    @apply border border-gray-300 rounded-md;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  html {
    scrollbar-width: none;
  }
}

@layer utilities {
  .bg-gradient-diagonal-lines {
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 2px,
      rgba(229, 231, 235, 0.5) 2px,
      rgba(229, 231, 235, 0.5) 4px
    );
    background-size: 40px 40px;
  }
  .custom-select .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0.5px !important;
  }
  .custom-select .ant-select-selector:hover,
  .custom-select .ant-select-selector:focus {
    border-right-width: 0.5px !important;
  }

  .custom-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-width: 0.5px !important;
  }

  .custom-button-revert {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0.5px !important;
  }

  .custom-button-revert:not(:disabled):hover {
    border-right-width: 0.5px !important;
    border-right-color: #ff6640 !important;
  }

  .dropdown-button {
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
  }

  .dropdown-button:hover {
    border-left-width: 1px;
  }

  .custom-tabs .ant-tabs-tab {
    font-size: 14px;
    border-bottom-color: #e5e7eb;
  }

  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }

  .custom-row-class td {
    background-color: #f3f4f6;
  }

  .cell-boder:first-child {
    border-left: 1px solid #e5e7eb;
  }

  .custom-menu .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .custom-menu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  .custom-menu .ant-menu-submenu .ant-menu-item {
    display: flex;
    align-items: center;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .printPages,
  .printPages * {
    visibility: visible;
  }
  .printPages {
    position: absolute;
    left: 0;
    top: 0;
    width: 297mm;
    height: 210mm;
    margin: 0%;
    padding: 0;
  }
}
